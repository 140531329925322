import { onWindowLoad } from 'public/src/pages/common/utils/index.js'
/**
 * tabIndex 优先级, 1 > 2 > 3 > ... > (0 || FormEle || a标签)
 *
 * 前言:
 * 采用 正tabIndex 目的是为了解决
 * a. 修改/优化 ADA体验时, 无需进行修改 DOM结构 这种不现实操作
 * b. 常规 Ele 与 0 地位相同而干扰聚焦次序
 *
 * W3C不建议使用 正tabIndex, 原因是觉得会扰乱用户的理解
 * 但是使用恰当时并不会有这种可能, 毕竟优先级是产品定的
 * 按规则填写v-ada参数即可, 各个板块应预留充足空间
 */

/**
 * ADA类
 * 1. 通过自定义字段标识元素, 提供同 tabIndex 嵌套/乱序/多同级聚合 的聚焦次序控制
 * 2. ↔ 可聚焦同级不同元素 ( v-for场景 )
 * 3. ↕ 可切入|退出嵌套结构 ( 树状场景 )
 * 4. level间控制分离
 * 5. 条件渲染元素序列控制集成
 */
class ADA {
  constructor() {
    document.addEventListener('focusin', () => this.syncFocus())
    document.addEventListener('keydown', (e) => this.switchFocus(e))
    onWindowLoad(() => document.querySelector(`[tabindex="1"]`)?.focus())
  }

  syncFocus() {
    const { FocusEle, adaLevel } = ADA.getFocusAdaEle()
    if (!FocusEle || FocusEle.tabIndex === +adaLevel) return

    document
      .querySelectorAll(`[data-ada-level="${adaLevel}"]`)
      .forEach((node) => (node.tabIndex = -1))
    FocusEle.tabIndex = adaLevel
    FocusEle.focus()
  }
  switchFocus(e) {
    const { FocusEle, adaLevel, adaPos, adaAdaption } = ADA.getFocusAdaEle()
    if (!FocusEle) return

    const { direction, ranking } = this.analysisKeyCode(e.code)
    const targetEle = this.getTarget({
      adaLevel,
      adaPos,
      adaAdaption,
      direction,
      ranking,
    })
    if (!targetEle) return

    targetEle.tabIndex = adaLevel
    targetEle.focus()
    FocusEle.tabIndex = -1
    ranking && e.preventDefault()
  }
  analysisKeyCode(code) {
    let direction, ranking
    switch (code) {
      case 'ArrowLeft':
        direction = -1
        break
      case 'ArrowRight':
        direction = 1
        break
      case 'ArrowUp':
        ranking = -1
        break
      case 'ArrowDown':
        ranking = 1
        break
    }
    return { direction, ranking }
  }
  getTarget({ adaLevel, adaPos, adaAdaption, direction, ranking }) {
    const arr = adaPos.split('-')
    if (direction) {
      let originPos = +arr.pop()
      const adaAdaption = ADA.getTargetDom({ adaLevel, adaPos: arr.join('-') })?.dataset.adaAdaption
      do {
        originPos += direction
        const target = ADA.getTargetDom({
          adaLevel,
          adaPos: [...arr, originPos].join('-'),
        })
        if (target) return target
      } while (originPos > 0 && originPos < +adaAdaption)
    }
    if (ranking) {
      if (ranking === 1) {
        let originPos = 0
        do {
          const target = ADA.getTargetDom({
            adaLevel,
            adaPos: [...arr, originPos].join('-'),
          })
          if (target) return target
        } while (++originPos <= +adaAdaption)
      } else {
        arr.pop()
        return ADA.getTargetDom({ adaLevel, adaPos: arr.join('-') })
      }
    }
  }

  static getFocusAdaEle() {
    if (typeof document === 'undefined') return {}

    const FocusEle = document.activeElement
    const { adaLevel, adaPos, adaAdaption } = FocusEle?.dataset || {}
    if (!adaLevel || !adaPos) return {}

    return { FocusEle, adaLevel, adaPos, adaAdaption }
  }
  static getTargetDom({ adaLevel, adaPos }) {
    if (!adaPos) return

    const selector = `[data-ada-level="${adaLevel}"][data-ada-pos="${adaPos}"]`
    return document.querySelector(selector)
  }
  static setSSRProps({ value: { level, pos, adaption } = {} }) {
    pos = (Array.isArray(pos) && pos.join('-')) || String(pos)
    const props = {
      'data-ada-level': level,
      'data-ada-pos': pos
    }
    if (adaption) {
      props['data-ada-adaption'] = adaption
    }
    return props
  }
  static focusAdaEle({ adaLevel, adaPos }) {
    if (typeof document === 'undefined') return
    ADA.getTargetDom({ adaLevel, adaPos })?.focus()
  }
  static setAdaData(el, { value: { level, pos, adaption } = {} }) {
    if (typeof document === 'undefined') return
    pos = (Array.isArray(pos) && pos.join('-')) || String(pos)

    const { FocusEle, adaLevel } = ADA.getFocusAdaEle()
    el.tabIndex = el === FocusEle || (level !== +adaLevel && pos === '0') ? level : -1

    el.dataset.adaLevel = level
    el.dataset.adaPos = pos
    adaption && (el.dataset.adaAdaption = adaption)
  }
}

export default ADA
