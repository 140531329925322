import controleCenter from './control-center'
import publisher from './publisher'
import subscriber from './subscriber'
import { daEventCenter as  daEventCenterV2, isAbcdEvent } from '@shein-aidc/basis-sa-event-center'
import analysisReport from './analysis/report'
import { SILogger } from '@/public/src/pages/common/monitor'

import eventIndex from './event/index'
class eventCenter {
  constructor() {
    // create channel control center
    this.controlCenter = new controleCenter()
    
    // create publisher
    this.publisher = new publisher({ controlCenter: this.controlCenter })
    
    // create subscribe
    this.subscriber = new subscriber({ controlCenter: this.controlCenter })
    
    this.event = new eventIndex({
      controlCenter: this.controlCenter,
      publisher: this.publisher,
      subscriber: this.subscriber
    })
    
    this.getExposeInstance = daEventCenterV2.getExposeInstance.bind(daEventCenterV2)
  }
  
  /**
   * 对外暴露绑定观察者
   */
  addSubscriber({ modulecode = '' } = {}) {
    if (!modulecode) return
    this.subscriber.subscribe({ modulecode })
  }
  
  /**
   * 对外暴露 手动触发通知事件
   */
  triggerNotice ({ target = '', daIdTag = '', daId = '', bindData = null, sendType = 'user', extraData = {} } = {}) {
    if (!daId) return
    // 没有传入dom
    if (daIdTag && !target) {
      const element = document.querySelector(`[${daIdTag}="${daId}"]`)
      target = element || null
    }
    sendType = sendType || 'user'
    
    if(!isAbcdEvent(daId)) {
      console.warn(`%c[eventCenter]The version of eventCenter used does not match ${daId}, you should import @shein-aidc/basis-sa-event-center, not public/src/services/eventCenter/index`, 'color: red; font-size: 16px;')
      
      SILogger.logWarn({
        tag: 'sa_track_report',
        message: 'sa report version exception',
        params: {
          id: daId
        }
      })
    }
    
    this.publisher.notify({ target, daId, sendType, extraData: extraData || {}, bindData })
  }
  
  /**
   * 对外暴露 上报方法
   */
  reportHandler({ sa = null, sensors = null } = {}) {
    analysisReport.report({ sa, sensors })
  }
  
  /**
   * 对外暴露 事件绑定方法
   * fix：事件冒泡被禁止的事件源
   */
  bindEvent({ event = '', daId = '' } = {}) {
    if (!event || !daId) return
    this.event.bindEventByDaId({
      event,
      daId
    })
  }
}

function getEventCenter() {
  
  if (typeof window === 'undefined') return {
    addSubscriber(){},
    triggerNotice(){},
    reportHandler(){},
    bindEvent(){},
    getExposeInstance() {},
  }
  if (window.daEventCenter) return window.daEventCenter

  const daEventCenter = window.daEventCenter = new eventCenter()
  return daEventCenter
}

const daEventCenter = getEventCenter()

export {
  daEventCenter
}
